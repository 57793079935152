import Landing from './Landing';
import MixRequest from './MixRequest';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
	<Router>
		<Routes>
			<Route path='/' element= {<Landing/>}></Route>
			<Route path='/mix-request' element = {<MixRequest/>}></Route>
		</Routes>
	</Router>
  );
}

export default App;
