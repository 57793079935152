
import React, { useEffect, useState } from "react";
import { Modal } from "./components/Modal";
import MyVideo from "./assets/videos/video1.mp4";
import "./css/style.css";
import "./css/tomix.css";



function Landing() {
  useEffect(() => {
    window.sr.reveal('.feature', {
      duration: 600,
      distance: '20px',
      easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      origin: 'right',
      viewFactor: 0.2
    })
  }, [])

  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  }
  return (
    <>
      <div className="body-wrap boxed-container">
        <header className="site-header">
          <div className="container">
            <div className="site-header-inner">
              <div className="brand header-brand">
                <h1 className="m-0">
                  <a href="#"></a>
                </h1>
              </div>
            </div>
          </div>
        </header>

        <main>
          <section className="hero">
            <div className="container">
              <div className="hero-inner">
                <div className="hero-copy">
                  <p
                    align="center"
                    style={{
                      "margin-top": "50px",
                      "margin-bottom": "50px",
                    }}
                  >
                    <img
                      className="header-logo-image asset-dark"
                      width="240px"
                      src={require("./images/logo.png")}
                      alt="Logo"
                    />
                  </p>
                  <h1
                    className="hero-title mt-0"
                    style={{ "font-size": "40px" }}
                    align="center"
                  >
                    #ToMixNoSeCorta
                  </h1>
                  <p className="hero-paragraph">
                    Disfruta miles de mix, en cada momento de tu vida
                  </p>
                  <div className="hero-cta">
                    <a
                      className="button button-primary"
                      href="https://webplayer.tomix.app/"
                    >
                      Ingresar
                    </a>
                    <a
                      className="button button-alternative"
                      href="https://webplayer.tomix.app/#/register"
                    >
                      Registrate gratis ahora
                    </a>
                  </div>
                  <br />
                </div>
                <div className="hero-media">
                  <div className="header-illustration">
                    <img
                      className="header-illustration-image asset-light"
                      src={require("./images/header-illustration-light.svg").default}
                      alt="Header illustration"
                    />
                    <img
                      className="header-illustration-image asset-dark"
                      src={require("./images/header-illustration-dark.svg").default}
                      alt="Header illustration"
                    />
                  </div>
                  <div className="hero-media-container">
                    <img
                      className="hero-media-image asset-light"
                      src={require("./images/tomix_white.png")}
                      alt="Hero media"
                    />
                    <img
                      className="hero-media-image asset-dark"
                      src={require("./images/tomix_white.png")}
                      alt="Hero media"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="features section">
            <div className="container">
              <div className="features-inner section-inner has-bottom-divider">
                <div className="features-header text-center">
                  <div className="container-sm">
                    <h2 className="section-title mt-0">CAMBIATE A PREMIUM</h2>
                    <button className='btn-video' onClick={handleModal}>Video instructivo</button>
                    <div className="features-image">
                      <img
                        className="features-box asset-dark"
                        src={require("./images/screen.png")}
                        alt="Feature box"
                      />
                      <img
                        className="features-illustration asset-dark"
                        src={require("./images/features-illustration-top-dark.svg").default}
                        alt="Feature illustration top"
                      />
                      <img
                        className="features-illustration asset-light"
                        src={require("./images/features-illustration-light.svg").default}
                        alt="Feature illustration"
                      />
                      <img
                        className="features-box asset-light"
                        src={require("./images/features-box-light.svg").default}
                        alt="Feature box"
                      />
                      <img
                        className="features-illustration asset-light"
                        src={require("./images/features-illustration-top-light.svg").default}
                        alt="Feature illustration top"
                      />
                    </div>
                  </div>
                </div>

                <div className="promotions">
                  <div className="promotions-div promotions-mensual">
                    <h3 className="promotions-title">Mensual</h3>
                    <p className="promotions-text mb-0 ">
                      Cancela cuando quieras
                    </p>
                    <span className='promotions-price'>S/.5.00</span>
                  </div>
                  <div className="promotions-div promotions-semestral">
                    <h3 className="promotions-title">semestral</h3>
                    <p className="promotions-text mb-0">
                      Un mes gratuito
                    </p>
                    <p className="promotions-text ">
                      Paga solo 05
                    </p>
                    <span className='promotions-price'>S/.25.00</span>
                  </div>
                  <div className="promotions-div promotions-anual">
                    <h3 className="promotions-title">anual</h3>
                    <p className="promotions-text mb-0">
                      Dos meses gratis
                    </p>
                    <p className="promotions-text">
                      Paga solo 10
                    </p>
                    <span className='promotions-price'>S/.50.00</span>
                  </div>
                </div>
                <div className="description">
                  <p className="description-text description-text-mark">Con To Mix Premium </p>
                  <p className="description-text">Acceso a todos los MIX</p>
                  <p className="description-text">Reproduce sin interrupciones</p>
                  <p className="description-text">Acceso a los mix de tus Djs Favoritos</p>
                  <p className="description-text">Puedes crear Tu Mix Favorito</p>
                  <p className="description-text">Envíanos tu playlist, nosotros lo hacemos por ti.</p>
                </div>
                <div className="create-mix" style={{ justifyContent: "center" }}>
                  <a
                    className="create-mix-button button button-alternative"
                    href="/mix-request"
                  >
                    Crea tu propio Mix
                  </a>
                  <p className="create-mix-text">Sólo Clientes Premium</p>
                </div>
              </div>
            </div>
          </section>

          <section className="cta section">
            <div className="container-sm">
              <div className="cta-inner section-inner">
                <div className="cta-header text-center">
                  <h2 className="section-title mt-0">
                    ¿Que estas esperando? Descargalo ahora
                  </h2>
                  <div className="cta-cta" align="center">
                    <a href="https://play.google.com/store/apps/details?id=com.inkastudios.tomix_app">
                      <img
                        style={{ paddingBottom: "20px" }}
                        className="header-logo-image asset-dark"
                        width="240px"
                        src={require("./images/playstore.png")}
                        alt="Logo"
                      />
                    </a>
                    <a href="https://apps.apple.com/pe/app/to-mix/id1567961940?l=en">
                      <img
                        className="header-logo-image asset-dark"
                        width="240px"
                        src={require("./images/ios.png")}
                        alt="Logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <footer className="site-footer has-top-divider">
          <div className="container">
            <div className="site-footer-inner">
              <div className="brand footer-brand">
                <a href="#">
                  <img
                    className="asset-light"
                    width="140"
                    src={require("./images/logo.png")}
                    alt="Logo"
                  />
                  <img
                    className="asset-dark"
                    width="140"
                    src={require("./images/logo.png")}
                    alt="Logo"
                  />
                </a>
              </div>
              <ul className="footer-links list-reset">
                <li>
                  <a
                    target="_blank"
                    href="https://cdn.tomix.app/documents/7d1db979-ebe6-46f7-a0e0-db37227d5224.pdf"
                  >
                    Política de copyright
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://cdn.tomix.app/documents/1c7b7f88-a222-49fc-b458-9b271372c5af.pdf"
                  >
                    Política de privacidad
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://cdn.tomix.app/documents/e609163e-9ebc-411c-925a-213a9eb9961f.pdf"
                  >
                    Términos y condiciones
                  </a>
                </li>
              </ul>
              <ul className="footer-social-links list-reset">
                <li>
                  <a href="#">
                    <span className="screen-reader-text">Facebook</span>
                    <svg
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"
                        fill="#FFF"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="screen-reader-text">Twitter</span>
                    <svg
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"
                        fill="#FFF"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="screen-reader-text">Google</span>
                    <svg
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z"
                        fill="#FFF"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
              <div className="footer-copyright">
                &copy; 2021, Todos los derechos reservados
              </div>
            </div>
          </div>
        </footer>
        <Modal show={showModal} closeModal={setShowModal} >
          <video width='100%' height='100%' controls autostart autoPlay src={MyVideo} type="video/mp4" />
        </Modal>
      </div>
    </>
  )
}

export default Landing;
