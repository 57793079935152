import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Modal,
  ThemeProvider,
  Snackbar,
  SnackbarContent,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
} from "@material-ui/core";

// import MDUIkit from "md-uikit";

import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

import settings from "./settings";
import axios from "axios";
// import UserActions from "../providers/user/user.actions";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {createTheme} from "@material-ui/core/styles"

import esLocale from "date-fns/locale/es";

import DateFnsUtils from "@date-io/date-fns";

import { getModalStyle, useStyles } from "./theme";
import NoData from "./components/noDataComponent";



let theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#f6c123",
    },
    secondary: {
      // This is green.A700 as hex.
      main: /* "#3949ab" */ "#0d47a1",
    },
    type: "dark",
    background: {
      default: "#000000",
    },
  },
  typography: {
    fontFamily: ["Google Sans", "Arial", "serif"].join(","),
  },
});

const MixRequest = () => {
  const history = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [mixName, setMixName] = useState("");
  const [allSongs, setAllSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [newSongs, setNewSongs] = useState([])
  const [countSelected, setCountSelected] = useState(0);

  const [openModal, setOpenModal] = useState(false);

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [chooseRegister, setChooseRegister] = useState(false);

  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
    country: "",
    city: "",
    genre: "1",
  });

  const [loading, setLoading] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [userData, setUserData] = useState({
    userId: "",
    userToken: "",
  });
  
  
  const [modalStyle] = useState(getModalStyle);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [birthday, setBirthday] = useState(new Date());

  const [snackBarState, setSnackBarState] = useState({
    open: false,
    type: "success",
    msg: "",
  });

  const { open, type, msg } = snackBarState;

  const alertTypes= {
    red: 'rgb(211, 47, 47)',
    green: 'rgb(56, 142, 60)',
    yellow: 'rgb(245, 124, 0)'
  }

  function handleSBOpen(type, msg) {
    setSnackBarState({ open: true, type: alertTypes[type], msg: msg });
  }

  function handleSBClose() {
    setSnackBarState({ open: false, type: "",  msg: ""});
  }

  useEffect(() => {
    fetchCountries();
    getSongs();
  }, []);

  useEffect(()=> {
    console.log("Sending mix after changes");
    if (userData.userId === "")
      return;
    sendMix();
  }, [userData.userId, userData.userToken])

  async function fetchCountries() {
    try {
      const response = await fetch(`${settings.connectionString}/countries`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      });
      if (response.status >= 400 && response.status < 500) {
        //console.log(response);
        throw "Error en el servidor";
      }
      if (response.status >= 500 && response.status < 600) {
        //console.log(response);
        throw "Error en el servidor";
      }

      const res = await response.json();

      setCountries(res);
    } catch (error) {
      handleSBOpen("red", error);
    }
  }

  async function fetchCities(countryId) {
    try {
      const response = await fetch(
        `${settings.connectionString}/cities/${countryId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );
      if (response.status >= 400 && response.status < 500) {
        //console.log(response);
        throw "Error en el servidor";
      }
      if (response.status >= 500 && response.status < 600) {
        //console.log(response);
        throw "Error en el servidor";
      }

      const res = await response.json();
      console.log("Countries: ", res);
      setCities(res);
    } catch (error) {
      handleSBOpen("red", error);
    }
  }

  const classes = useStyles();
  const {
    firstName,
    lastName,
    country,
    city,
    repassword,
    email,
    password,
    genre,
  } = userCredentials;

  const handleSearchChange = (event) => {
    const { value, name } = event.target;
    setSearchValue(value);
    let formated = value.toLowerCase();
    console.log(formated);
    const searchedSongs = allSongs.filter(
      (v) =>
        v.title.toLowerCase().includes(formated) ||
        v.author?.name.toLowerCase().includes(formated)
    );
    setFilteredSongs(searchedSongs);
  };

  const handleSelection = ({ selectedRows }) => {
    setSelectedSongs(selectedRows);
    console.log("Selected: ", selectedRows);
    if (selectedRows) setCountSelected(selectedRows.length);
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleLoginOpen = () => {
    if (selectedSongs.length === 0) {
      alert("Selecciona al menos una canción");
      return;
    }
    if (selectedSongs.length > 25) {
      alert("Solo puedes seleccionar hasta 25 canciones");
      return;
    }

    if (mixName === "") {
      alert("Ingresa primero un nombre del mix");
      return;
    }
    setOpenLoginModal(true);
  };
  const handleLoginClose = () => setOpenLoginModal(false);

  const handleNameChange = (event) => {
    const { value, name } = event.target;
    setMixName(value);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === "country") {
      fetchCities(value);
      console.log("Fetch cities", userCredentials);
      setUserCredentials({ ...userCredentials, city: "", [name]: value });
      return;
    }
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const sendMix = async () => {
    console.log("User adata:", userData)
    if (userData.userId === "" || userData.userToken === ""){
      handleSBOpen('red', 'Tiene que loguearse o registrarse.')
    }
    let songRequests = selectedSongs.map((v) => {
      return { id: 0, songId: v.id, mixRequestId: 0,
        songName: "",
        authorName: "", };
    });
    let newSongRequests = newSongs.map(v => ({
      id: 0,
      songId: null,
      songName: v.songName,
      authorName: v.songAuthor,
      mixRequestId: 0,
    }))
    let bodyData = {
      id: 0,
      name: mixName,
      clientId: userData.userId,
      songRequests: songRequests.concat(newSongRequests),
    };
    console.log(songRequests, bodyData);
    try {
      const { data } = await axios.post(
        `${settings.connectionString}/mix-requests`,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${userData.userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(data);
      selectedSongs.splice(0, selectedSongs.length);
      setSelectedSongs([]);
      setNewSongs([])
      console.log("After: ", selectedSongs);
      setMixName("");
      setCountSelected(0);
      handleLoginClose();
      handleClose();
      handleSBOpen("green", "Solicitud de Mix creada satisfactoriamente.")
    } catch (err) {
      console.log(err);
      handleSBOpen("red", "Hubo un error al crear el mix")
    }

    handleClose();
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Author",
      selector: (row) => row.author?.name,
    },
    {
      name: "Album",
      selector: (row) => row.album,
    },
  ];

  //   const user = UserActions();
  const getSongs = async () => {
    console.log(settings);
    setLoading(true);
    const { data } = await axios.get(`${settings.connectionString}/songs`, {
      headers: {
        // Authorization: `Bearer ${user.currentUser.token}`,
        "Content-Type": "application/json",
      },
    });
    setLoading(false);
    setAllSongs(data);
    setFilteredSongs(data);
  };

  async function register() {
    birthday.setDate(birthday.getDate() - 1);
    let stringBirthday = birthday.toJSON().slice(0, 10);
    const data = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      confirmPassword: password,
      phoneNumber: "1234",
      cityId: city,
      dateOfBirth: stringBirthday,
      personGenreId: parseInt(genre),
    };
    console.log("Register:", data);
    setIsAuthLoading(true);
    try {
      const response = await fetch(
        `${settings.connectionString}/auth/register`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (response.status >= 400 && response.status < 500) {
        //console.log(response);
        setIsAuthLoading(false);
      }
      if (response.status >= 500 && response.status < 600) {
        //console.log(response);
        setIsAuthLoading(false);
      }

      /* const text = await response.text();
            //console.log(text); */
      //console.log(response.status);
      const res = await response.json();
      //console.log(res);
      var id = res["id"];
      if (id != null) {
        login();
      } else {
        throw "Este email ya está registrado";
      }

      setIsAuthLoading(false);
    } catch (error) {
      handleSBOpen('red', error);
      setIsAuthLoading(false);
    }
  }
  async function login() {
    const data = {
      email: email,
      password: password,
    };
    console.log("lOGIN: ", data);
    var flagResponse = false;
    try {
      const response = await fetch(`${settings.connectionString}/auth/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      if (response.status >= 400 && response.status < 500) {
        //console.log(response);
        flagResponse = true;
      }
      if (response.status >= 500 && response.status < 600) {
        //console.log(response);
        flagResponse = true;
      }

      const res = await response.json();
      if (flagResponse) {
        console.log(data);
        if (res.errorMessage) {
          handleSBOpen('red', res.errorMessage);
        }
      } else {
        let Rol = 2;

        // user.setCurrentUser({
        //   clientId: res.client.id,
        //   rol: Rol,
        //   name: res.client.firstName + " " + res.client.lastName,
        //   token: res.access_token,
        //   ...res.client,
        // });
        // Send Mix
        setUserData({
          userToken: res.access_token,
          userId: res.client.id,
        })
      }
    } catch (error) {
      handleSBOpen('red', error);

      //console.log(error);
    }
  }


  return (
    <div
      className="App"
      style={{
        paddingTop: "20px",
        paddingLeft: "40px",
        paddingRight: "40px",
        backgroundColor: "black",
        height: "100vh",
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleSBClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarContent
          style={{
            backgroundColor: type,
          }}
          message={msg}
        />
      </Snackbar>
      <ThemeProvider theme={theme}>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={modalStyle} className={classes.paper}>
            <Typography id="modal-modal-title">
              Ponga un nombre al mix
            </Typography>
            <br></br>
            <TextField
              fullWidth
              label="Nombre"
              size="small"
              value={mixName}
              onChange={handleNameChange}
              variant="outlined"
            />
            <br />
            <Box mt={"5"}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                style={{ marginBottom: "1em" }}
              >
                Mix de {countSelected + newSongs.length} canciones.
              </Typography>
            </Box>
            <Box display="flex" sx={{ justifyContent: "flex-end" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleLoginOpen}
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={openLoginModal}
          onClose={handleLoginClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={modalStyle} className={classes.loginModal}>
            <Typography id="modal-modal-title">
            <a onClick={() => setChooseRegister(false)}>Ingrese</a> a su cuenta o{" "}
              <a onClick={() => setChooseRegister(true)}>regístrese</a> para
              enviar el mix
            </Typography>
            <br />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              {!chooseRegister ? (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email:"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"

                    style={{ marginBottom: ".5em" }}
                  />
                  <TextField
                    size="small"
                    fullWidth
                    label="Contraseña"
                    variant="outlined"
                    type={hiddenPassword ? "password" : "text"}
                    onChange={handleChange}
                    value={password}
                    name="password"
                    style={{ marginBottom: "1em" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setHiddenPassword(!hiddenPassword)}
                          >
                            {hiddenPassword ? (
                              <Icon>visibility_off</Icon>
                            ) : (
                              <Icon>visibility</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box display="flex" alignItems="end">
                    <Box ml={"auto"}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={login}
                      >
                        Ingresar
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres:"
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Apellidos:"
                      name="lastName"
                      value={lastName}
                      onChange={handleChange}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="País:"
                      name="country"
                      select
                      value={country}
                      onChange={handleChange}
                      variant="filled"
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Ciudad:"
                      name="city"
                      select
                      value={city}
                      onChange={handleChange}
                      variant="filled"
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="email"
                      label="Email:"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      variant="filled"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <DatePicker
                        disableFuture
                        fullWidth
                        size="small"
                        variant="filled"
                        openTo="year"
                        format="dd/MM/yyyy"
                        label="Fecha de Nacimiento"
                        views={["year", "month", "date"]}
                        value={birthday}
                        onChange={setBirthday}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <FormLabel id="gender-label">¿Cuál es tu género?</FormLabel>
                    <RadioGroup
                      aria-labelledby="gender-label"
                      style={{ justifyContent: "space-evenly" }}
                      name="genre"
                      value={genre}
                      onChange={handleChange}
                      row
                      size="small"
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Hombre"
                        size="small"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="Mujer"
                        size="small"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio color="primary" />}
                        label="No binario"
                        size="small"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Contraseña"
                      variant="filled"
                      type={hiddenPassword ? "password" : "text"}
                      onChange={handleChange}
                      value={password}
                      name="password"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment InputAdornment position="end">
                            <IconButton
                              onClick={() => setHiddenPassword(!hiddenPassword)}
                            >
                              {hiddenPassword ? (
                                <Icon>visibility_off</Icon>
                              ) : (
                                <Icon>visibility</Icon>
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Repetir Contraseña"
                      variant="filled"
                      type={hiddenPassword ? "password" : "text"}
                      onChange={handleChange}
                      value={repassword}
                      name="repassword"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment InputAdornment position="end">
                            <IconButton
                              onClick={() => setHiddenPassword(!hiddenPassword)}
                            >
                              {hiddenPassword ? (
                                <Icon>visibility_off</Icon>
                              ) : (
                                <Icon>visibility</Icon>
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box pb={2} pt={2}>
                      <Button
                        onClick={register}
                        fullWidth
                        variant="contained"
                        style={{ borderRadius: "25px" }}
                        color="primary"
                        disabled={isAuthLoading}
                      >
                        Crear cuenta
                      </Button>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Modal>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <IconButton aria-label="" onClick={() => history(-1)}>
              <Icon className="md-color-white">navigate_before</Icon>
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h5" className="md-color-white">
              Crea tu Mix
            </Typography>
            <Typography variant="small" className="md-color-white">
              {countSelected} canciones seleccionadas, {newSongs.length} canciones sugeridas
            </Typography>
          </Box>
          <Box ml={"auto"}>
            <Button color="primary" variant="contained" onClick={handleOpen}>
              Enviar
            </Button>
          </Box>
        </Box>
        <br />
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ marginX: "100px" }}
        >
          <Grid item xs={12} mt={10}>
            <TextField
              fullWidth
              label="Buscar canciones:"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <Icon>search</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <DataTable
            columns={columns}
            data={filteredSongs}
            selectableRows
            theme="dark"
            pagination="10"
            progressPending={loading}
            progressComponent={<h3>Cargando canciones...</h3>}
            onSelectedRowsChange={handleSelection}
            noDataComponent={<NoData setNewSongs={setNewSongs} songs={newSongs}/>}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default MixRequest;
