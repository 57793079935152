import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  Grid,
  ThemeProvider,
} from "@material-ui/core";

import React, { useState } from "react";

import { getModalStyle, useStyles } from "../theme";
import { createTheme } from "@material-ui/core/styles";

let theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#f6c123",
    },
    secondary: {
      // This is green.A700 as hex.
      main: /* "#3949ab" */ "#0d47a1",
    },
    type: "dark",
    background: {
      default: "#000000",
    },
  },
  typography: {
    fontFamily: ["Google Sans", "Arial", "serif"].join(","),
  },
  spacing: 8,
});

const NoData = ({setNewSongs, songs}) => {
  

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const [songFields, setSongFields] = useState({
    songName: "",
    songAuthor: "",
  });

  const { songName, songAuthor } = songFields;

  const handleChange = (event) => {
    const { value, name } = event.target;
    setSongFields({ ...songFields, [name]: value });
  };

  const saveSong = () => {
    // TODO
    let newSong = {
      authorId: null,
      id: null,
      songName: songName,
      songAuthor: songAuthor
    };
    setNewSongs([...songs, newSong])
    console.log(songs);
    handleClose();
    setSongFields({
      songName: "",
      songAuthor: "",
    })
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          
        >
          <Box style={modalStyle} className={classes.paper}>
            <Typography id="modal-modal-title">
              Ingrese el nombre y el autor de la canción:
            </Typography>
            <br></br>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="text"
                  label="Nombre de la canción:"
                  name="songName"
                  value={songName}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  style={{ marginBottom: ".5em" }}
                />
                <TextField
                  size="small"
                  fullWidth
                  label="Autor:"
                  variant="outlined"
                  type="text"
                  onChange={handleChange}
                  value={songAuthor}
                  name="songAuthor"
                  style={{ marginBottom: "1em" }}
                />
                <Box display="flex" justifyContent={"center"}>
                  <Box>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={saveSong}
                    >
                      Crear
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Grid container justifyContent="center">
          <Box sx={{ my: "1em" }}>
            <Box textAlign={"center"}>
              <Typography>No hubo resultados. Pero puedes sugerir una canción también</Typography>
            </Box>
            <Box textAlign={"center"} paddingTop="1em">
              <Button color="primary" variant="contained" onClick={handleOpen}>
                Sugerir nueva canción
              </Button>
            </Box>
          </Box>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default NoData;
