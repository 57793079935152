import React from 'react'

import './modal.css';
export const Modal = ({ show = false, closeModal, children }) => {
  const handleCloseModal = () => {
    closeModal(false)
  }
  return (
    <>
      {
        show &&
        (<div className='modal'>
          <div className='modal-overlay'></div>
          <button className='modal-close' onClick={handleCloseModal}>X</button>
          <div className='modal-content'>
            {children}
          </div>
        </div>)
      }
    </>
  )
}
